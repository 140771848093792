var prodcat = prodcat || {};
var site = site || {};
window.site = site || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
(function($) {
  $(document).on('inv_status_display:updated', '.js-product', function() {
    var $statusList = $('.js-inv-status-list', $(this)),
        skuBaseId = $statusList.data('sku-base-id'),
        skuData = prodcat.data.getSku(skuBaseId);

    if (skuData.INVENTORY_STATUS === 3) {
      if ($('.product__notify-me', $(this)).data('show-basic-oos-notify') === 0) {
        //LOP : Basic.
        if (skuData.LIFE_OF_PRODUCT !== 3) {
          $(window).trigger('notifyMe::show', [skuData, skuBaseId]);
          $(window).trigger('notifyMe::toggleAddToBag', ['hide', skuData]);
        }
      }
    } else if (skuData.INVENTORY_STATUS === 2) {
      if (skuData.LIFE_OF_PRODUCT !== 3) {
        $(window).trigger('notifyMe::show', [skuData, skuBaseId]);
        $(window).trigger('notifyMe::toggleAddToBag', ['hide', skuData]);
      }
    }
  });
})(jQuery);
